import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  Power,
  AlertOctagon,
  Award,
  Database,
  User,
  RotateCw,
  FileText,
} from 'react-feather';
import {Navbar, Nav, NavItem} from 'reactstrap';
import styled from 'styled-components';
import {get} from 'lodash';

import {refreshParams} from '../../../actions/app';
import {getProjectId} from '../../../lib/firebase';
import {isUserFreemium} from '../../../lib/user';

import ForceOnboarding from './forceonboarding';
import Admin from '../admin';
import UsersModal from './users-modal';

class Debugger extends Component {
  constructor(props) {
    super();
    this.state = {
      projectId: getProjectId(),
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleProjectId = this.handleProjectId.bind(this);
  }

  handleToggle(key, value) {
    localStorage.setItem(key, value);
    this.props.refreshParams();
  }

  handleProjectId() {
    const newProject =
      this.state.projectId === 'hubware-staging-firebase'
        ? 'hubware-production-firebase'
        : 'hubware-staging-firebase';

    const reload = window.confirm(
      `Are you sure wanted to switch to ${newProject}? The page will be reloaded.`
    );
    if (reload) {
      localStorage.setItem(
        'production',
        newProject === 'hubware-production-firebase'
      );
      localStorage.setItem(
        'staging',
        newProject === 'hubware-staging-firebase'
      );
      window.location.reload();
    }
  }

  render() {
    const {debug, isAdmin, offline, user, settingId} = this.props;
    const projectId = getProjectId();
    const settingUserEmail = user?.uiSettings.email ?? 'email unknown';
    const settingUserId = user?.id;
    return (
      <Admin>
        <Navbar
          className="inner-navbar justify-content-end"
          style={{left: 'auto'}}
          fixed="bottom"
          color="white"
          expand="sm"
          light
        >
          <Nav>
            {isUserFreemium && (
              <StyledNavItem className="text-primary">
                <ForceOnboarding user={user} />
              </StyledNavItem>
            )}
            <UsersModal>
              <RotateCw size="15" /> <small>switch account</small>
            </UsersModal>
            <StyledNavItem className="text-primary">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`
                    https://console.firebase.google.com/u/0/project/${projectId}/firestore/data~2Fusers~2F${settingUserId}
                    `}
              >
                <User size="15" />{' '}
              </a>
            </StyledNavItem>
            <StyledNavItem className="text-primary">
              <strong className="mr-2">{settingUserEmail}</strong>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`
                    https://console.firebase.google.com/u/0/project/${projectId}/firestore/data~2Fsettings~2F${settingId}
                    `}
              >
                <FileText size="15" />{' '}
              </a>
              <small className="ml-1">{settingId}</small>
            </StyledNavItem>

            <StyledNavItem
              className={`text-${
                projectId === 'hubware-production-firebase' ? 'warning' : 'dark'
              } clic`}
              onClick={this.handleProjectId}
            >
              <Database size="15" /> {projectId.split('-')[1]}
            </StyledNavItem>
            <StyledNavItem
              className={`text-${debug ? 'success' : 'danger'} clic`}
              onClick={() => this.handleToggle('debug', !debug)}
            >
              <AlertOctagon size="15" /> Debug {debug ? 'on' : 'off'}
            </StyledNavItem>
            <StyledNavItem className={`text-${offline ? 'danger' : 'success'}`}>
              <Power size="15" /> {offline ? 'Offline' : 'Online'}
            </StyledNavItem>
            {isAdmin && (
              <StyledNavItem className="text-success">
                <Award size="15" /> Admin
              </StyledNavItem>
            )}
          </Nav>
        </Navbar>
      </Admin>
    );
  }
}

export default connect(
  (state, props) => ({
    debug: state.app.debug,
    isAdmin: state.app.isAdmin,
    offline: state.app.offline,
    user: state.firestore.user,
    settingId: get(state, 'firestore.setting.id'),
  }),
  (dispatch) => ({
    ...bindActionCreators(
      {
        refreshParams,
      },
      dispatch
    ),
  })
)(Debugger);

Debugger.propTypes = {
  debug: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  offline: PropTypes.bool.isRequired,
  refreshParams: PropTypes.func.isRequired,
  user: PropTypes.object,
  settingId: PropTypes.string,
};

const StyledNavItem = styled(NavItem).attrs({
  className: 'd-flex align-items-center small ml-2',
})`
  svg {
    margin-right: 5px;
  }
`;
