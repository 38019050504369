import React, {useState} from 'react';
import {Row, Col} from 'reactstrap';
import {useTranslation} from 'react-i18next';

import {Card} from '../../../../components/card';

import Infos from './infos';
import TopList from '../../components/top-list';
import MacrosTable from './macros-table';
import IntentsTable from './intents-table';
import Dropdown from './dropdown';

import useMacros from './useMacros';
import useIntents from './useIntents';

const AnalyticsMacros = ({
  dateRange,
}: {
  dateRange: {startDate: Date; endDate: Date};
}) => {
  const {t} = useTranslation();
  const [macro, setMacro] = useState('');

  const {
    slice,
    setSlice,
    loading,
    formatedMacroData,
    getTopMacroRefused,
  } = useMacros({
    dateRange,
  });

  const {getTopIntentsRefused} = useIntents({dateRange});

  return (
    <>
      <Card className="mb-4">
        <Infos />
      </Card>

      <Row>
        <Col md="6">
          <TopList
            slice={slice}
            values={getTopMacroRefused}
            setSlice={setSlice}
            isLoading={loading}
            title={t('Top templates')}
            subTitle={t(
              'Manually selected by advisors for categories to which they are not associated.'
            )}
          />
        </Col>
        <Col md="6">
          <TopList
            slice={slice}
            values={getTopIntentsRefused}
            setSlice={setSlice}
            isLoading={loading}
            title={t('Top categories')}
            subTitle={t(
              'On which advisers oftenly use a different templates than associated ones'
            )}
          />
        </Col>
      </Row>
      <Row>
        <Dropdown
          macro={macro}
          formatedMacroData={formatedMacroData}
          setMacro={setMacro}
        />
      </Row>

      <Card className="mb-4">
        <div className="card-body">
          <h4>
            {t(`Utilisation par ${macro ? 'catégorie' : 'modèle de réponse'}`)}
          </h4>

          <h6 className="card-subtitle mb-3 text-muted">
            {macro
              ? t(
                  'Categories for which the template has been selected by the advisers rather than the one suggest by the Assistant'
                )
              : t(
                  'Statistics on usage of the 10 templates most often selected by advisors instead of those suggested by the Assistant'
                )}
          </h6>

          {macro ? (
            <IntentsTable macro={macro} dateRange={dateRange} />
          ) : (
            <MacrosTable
              formatedMacroData={formatedMacroData}
              loading={loading}
              setMacro={setMacro}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default AnalyticsMacros;
