import React, {useMemo} from 'react';
import styled from 'styled-components';
import {PlusCircle} from 'react-feather';
import i18n from '../../../../i18n';

import {NO_ASSISTANCE_USAGES_FULL} from '../../../../lib/analytics';
import Table from '../../../../components/table';
import {USAGES} from './utils';

export const MacrosTable = ({
  formatedMacroData,
  loading,
  setMacro,
}: {
  formatedMacroData: Array<Object>;
  loading: boolean;
  setMacro: Function;
}) => (
  <StyledTable>
    <Table
      isLoading={loading}
      data={formatedMacroData}
      columns={useMemo(
        () => [
          {
            accessor: 'id',
            Header: '',
            Cell: ({cell: {value}}: {cell: {value: string}}) => (
              <PlusCircle
                size={12}
                className="clic"
                onClick={() => setMacro(value)}
                id={`mapped-${value}`}
              />
            ),
          },
          {
            accessor: 'title',
            Header: i18n.t('Title'),
          },
          ...NO_ASSISTANCE_USAGES_FULL.map((accessor) => ({
            accessor,
            Header: USAGES[accessor].title,
            Cell: ({cell: {value}}: {cell: {value?: string}}) =>
              value ? value : <span>0</span>,
          })),

          {
            Header: 'Total',
            accessor: 'total',
          },
        ],
        [setMacro]
      )}
      csvFilename="Templates"
    />
  </StyledTable>
);

export default MacrosTable;

const StyledTable = styled.div`
  th:first-child {
    width: 2px;
  }

  th:last-child,
  td:last-child {
    width: 60px;
    text-align: right;
    font-weight: bold;
  }
`;
