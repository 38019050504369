import React from 'react';
import i18n from '../../../../i18n';
import styled from 'styled-components';

const Infos = () => (
  <div className="card-body">
    <h4 className="card-title">{i18n.t('Insights of the Assistant usage')}</h4>
    <hr />
    {Steps.map(({step, text}) => (
      <div
        key={step}
        className="d-flex justify-content-start align-items-center mt-1"
      >
        <StyledStep key={step}>{step}</StyledStep>

        <h6 className="card-subtitle text-muted ml-3">{text}</h6>
      </div>
    ))}
  </div>
);

export default Infos;

const StyledStep = styled.div`
  background-color: var(--hubware);
  border-radius: 25px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  height: 25px;
  line-height: 9px;
  margin: 9px;
  padding: 9px;
  position: relative;
  text-align: center;
  user-select: none;
  width: 25px;
  z-index: 100;
`;

const Steps = [
  {
    step: 1,
    text: i18n.t(
      'Learn about the templates oftenly used by advisors instead of those suggested by the Assistant, and the categories where they are most ofent substituted.'
    ),
  },
  {
    step: 2,
    text: i18n.t(
      'View the details of the 10 most used response models instead of those suggested by type of assistance.'
    ),
  },
  {
    step: 3,
    text: i18n.t(
      'Explore the detail of the categories on which each tempate is used by avisers while it is not associated with it.'
    ),
  },
  {
    step: 4,
    text: i18n.t(
      `Click the icon and go to Setup to associate these templates with categories, and improve the performance of the Assistant.`
    ),
  },
];
