import {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useQuery} from 'graphql-hooks';
import {find, reduce, groupBy, sortBy, map} from 'lodash';

import {NO_ASSISTANCE_USAGES_FULL} from '../../../../lib/analytics';
import getDataHook from '../../../../lib/graphQL';
import {TEMPLATES} from '../../../../reducer/types/firestore-types';
import {getAllTemplates} from '../../../../selectors/templates';
import {getTop} from './utils';

type TEMPLATE_QUERY_DATA = {
  botUsage: {
    aggregation: {value: string; macroid: string};
    value: number;
  }[];
};

type TEMPLATE_FORMATED_DATA = {
  id: string;
  title: string;
  AUTO_REPLY_NOT_USED: number;
  MACRO_AUTO_NOT_USED: number;
  SUGGESTION_NOT_USED: number;
  NO_ASSISTANCE: number;
  total: number;
};

const useMacros = ({
  dateRange,
}: {
  dateRange: {startDate: Date; endDate: Date};
}) => {
  const [slice, setSlice] = useState(5);
  const allMacros: TEMPLATES[] = useSelector(getAllTemplates);

  const query = getDataHook('getMacros', {date: dateRange})[0];
  const variables = getDataHook('getMacros', {date: dateRange})[1];

  const {
    data,
    loading,
  }: {data?: TEMPLATE_QUERY_DATA; loading: boolean} = useQuery(
    query,
    variables
  );

  const formatMacroData = (
    data: TEMPLATE_QUERY_DATA,
    allMacros: TEMPLATES[]
  ): TEMPLATE_FORMATED_DATA[] => {
    const mappedTmp = map(
      groupBy(data?.botUsage ?? [], 'aggregation.macroid'),
      (usages, macro) => ({
        id: macro === 'null' ? 'Réponse-libre' : macro,
        AUTO_REPLY_NOT_USED:
          find(usages, (u) => u.aggregation.value === 'AUTO_REPLY_NOT_USED')
            ?.value ?? 0,
        MACRO_AUTO_NOT_USED:
          find(usages, (u) => u.aggregation.value === 'MACRO_AUTO_NOT_USED')
            ?.value ?? 0,
        SUGGESTION_NOT_USED:
          find(usages, (u) => u.aggregation.value === 'SUGGESTION_NOT_USED')
            ?.value ?? 0,
        NO_ASSISTANCE:
          find(usages, (u) => u.aggregation.value === 'NO_ASSISTANCE')?.value ??
          0,
      })
    );

    return mappedTmp.map(({id, ...rest}) => ({
      id,
      title: allMacros.find((tmp) => id === tmp.id)?.title ?? id,
      ...rest,
      total: reduce(rest, (sum, n) => sum + n),
    }));
  };

  const formatedMacroData = useMemo(
    () =>
      sortBy(formatMacroData(data, allMacros), ['total'])
        .reverse()
        .filter((t) => t.title !== 'Réponse-libre')
        .slice(0, 10),
    [allMacros, data]
  );

  const getTopMacroRefused = useMemo(
    () => getTop(NO_ASSISTANCE_USAGES_FULL, formatedMacroData),
    [formatedMacroData]
  );

  return {
    slice,
    setSlice,
    loading,
    formatedMacroData,
    getTopMacroRefused,
  };
};

export default useMacros;
