import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {reduce, find, groupBy, map} from 'lodash';

import {useQuery} from 'graphql-hooks';
import getDataHook from '../../../../lib/graphQL';
import {INTENTS, getAllIntents} from '../../../../selectors/intents';
import {REFUSED_ASSISTANCE_USAGES} from '../../../../lib/analytics';
import {getTop} from './utils';

type INTENT_QUERY_DATA = {
  botUsage: {
    aggregation: {value: string; macroid: string; intent: string};
    value: number;
  }[];
};

type INTENT_FORMATED_DATA = {
  key: string;
  title: string;
  AUTO_REPLY_NOT_USED: number;
  MACRO_AUTO_NOT_USED: number;
  SUGGESTION_NOT_USED: number;
  NO_ASSISTANCE: number;
  total: number;
};

const useIntents = ({
  dateRange,
  macro,
}: {
  dateRange: {startDate: Date; endDate: Date};
  macro?: string;
}) => {
  const allIntents: INTENTS = useSelector(getAllIntents);

  const query = getDataHook('getMacroIdAgg', {date: dateRange})[0];
  const variables = getDataHook('getMacroIdAgg', {date: dateRange})[1];
  const {
    data,
    loading,
  }: {data?: INTENT_QUERY_DATA; loading: boolean} = useQuery(query, variables);

  const formatIntentData = (
    data: INTENT_QUERY_DATA,
    intents: {},
    macro?: string
  ): INTENT_FORMATED_DATA[] => {
    const groupedByIntent = macro
      ? groupBy(
          groupBy(data?.botUsage ?? [], 'aggregation.macroid')[macro] ?? [],
          'aggregation.intent'
        )
      : groupBy(data?.botUsage ?? [], 'aggregation.intent');

    const mappedTmp = map(groupedByIntent, (usages, key) => ({
      key: key,
      title: intents[key]?.title,
      AUTO_REPLY_NOT_USED:
        find(usages, (u) => u.aggregation.value === 'AUTO_REPLY_NOT_USED')
          ?.value ?? 0,
      MACRO_AUTO_NOT_USED:
        find(usages, (u) => u.aggregation.value === 'MACRO_AUTO_NOT_USED')
          ?.value ?? 0,
      SUGGESTION_NOT_USED:
        find(usages, (u) => u.aggregation.value === 'SUGGESTION_NOT_USED')
          ?.value ?? 0,
      NO_ASSISTANCE:
        find(usages, (u) => u.aggregation.value === 'NO_ASSISTANCE')?.value ??
        0,
    }));

    return mappedTmp.map(({key, title, ...rest}) => ({
      key,
      title,
      ...rest,
      total: reduce(rest, (sum, n) => sum + n),
    }));
  };

  const formatedIntentData = useMemo(
    () =>
      formatIntentData(data, allIntents, macro).filter((i) => i.key !== 'null'),
    [allIntents, data, macro]
  );

  const formatedTopIntentData = useMemo(
    () => formatIntentData(data, allIntents).slice(0, 10),
    [allIntents, data]
  );

  const getTopIntentsRefused = useMemo(
    () => getTop(REFUSED_ASSISTANCE_USAGES, formatedTopIntentData),
    [formatedTopIntentData]
  );

  return {
    getTopIntentsRefused,
    formatedIntentData,
    loading,
  };
};

export default useIntents;
