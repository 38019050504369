import {sortBy} from 'lodash';

export const getTop = (
  keys: string[],
  values: {
    title: string;
    AUTO_REPLY_NOT_USED: number;
    MACRO_AUTO_NOT_USED: number;
    SUGGESTION_NOT_USED: number;
    NO_ASSISTANCE: number;
    total: number;
  }[]
) => {
  const summedValues = values.map((v) => ({
    key: v.title,
    value: v.total,
  }));
  return sortBy(summedValues, ['value']).reverse();
};

export const USAGES = {
  AUTO_REPLY_NOT_USED: {
    title: 'Auto. substituées',
  },
  MACRO_AUTO_NOT_USED: {
    title: 'Préparées substituées',
  },
  SUGGESTION_NOT_USED: {
    title: 'Assistées substituées',
  },
  NO_ASSISTANCE: {
    title: 'Sans assistance',
  },
};
