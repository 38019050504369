// @flow
import {chain, compact, find, get, groupBy, mapValues} from 'lodash';
import {createSelector} from 'reselect';
import {getCodeFromActions} from '../lib/templates';
import {getAllActions, type ACTION_INTERFACE} from './actions';

import {TEMPLATES} from '../reducer/types/firestore-types';

export const assistanceTypes = {
  prepared: 'Préparée',
  autoReplied: 'Automatique',
  assisted: 'Assistée',
};

export const fullAssistanceTypes = {
  ...assistanceTypes,
  none: 'Sans assistance',
};

export type AssistanceType = $Keys<typeof assistanceTypes>;
export type FullAssistanceType = $Keys<typeof fullAssistanceTypes>;

export type TEMPLATE_INTERFACE = {
  id: string,
  title: string,
  assistanceType: FullAssistanceType,
  intents: Array<string>,
  variables: Array<string>,
  actions: Object | Array<Object>,
  requestsActions: number,
  filter: ?{
    description: string,
    id: string,
    script: string,
    disabled: boolean,
  },
};

export type MAPPED_TEMPLATES = {
  [key: string]: TEMPLATE_INTERFACE[],
};

const PATHS = {
  suggested: 'firestore.setting.macroIntentPairs',
  prepared: 'firestore.setting.autoApplyMacroIds',
  autoReply: 'firestore.setting.autoReplyConfiguration.macroIds',
  mapping: 'firestore.setting.macroIntentPairs',
  filters: 'firestore.setting.macroFilters',
};

const allTmps = (state): Array<TEMPLATE_INTERFACE> =>
  chain(state)
    .get('firestore.templates', [])
    .map(({id, title, actions}) => ({
      id: id.toString(),
      title: title || id.toString(),
      intents: listIntentsByTemplateId(state, id.toString()),
      variables: getVariables(actions),
      actions,
      assistanceType: getAssistanceType(state, id.toString()),
      filter: getFilter(state, id.toString()),
    }))
    .orderBy([({title}) => title.toLowerCase()])
    .value();

const mappedTmps = (state): Object => {
  const mapping = get(state, PATHS.mapping, []);
  return mapping.map(({intent, macro}) => ({
    intent,
    id: macro.toString(),
  }));
};

export const getAllTemplates: TEMPLATES[] = createSelector(
  [allTmps, getAllActions],
  (allTmps, allActions: Array<ACTION_INTERFACE>): TEMPLATE_INTERFACE =>
    allTmps.map((t) => ({
      ...t,
      requestsActions: allActions.filter(({templates = []}) =>
        templates.includes(t.id)
      ).length,
    }))
);

export const getMappedTemplatesByIntents: MAPPED_TEMPLATES = createSelector(
  [getAllTemplates, (state) => groupBy(mappedTmps(state), 'intent')],
  (allTmps, mappedTmpsByIntents) =>
    mapValues(mappedTmpsByIntents, (tmps) =>
      compact(tmps.map(({id}) => find(allTmps, {id})))
    )
);

export const getMappedTemplatesById: MAPPED_TEMPLATES = createSelector(
  [getAllTemplates, (state) => groupBy(mappedTmps(state), 'id')],
  (allTmps, mappedTmpsByIntents) =>
    mapValues(mappedTmpsByIntents, (tmps) =>
      compact(tmps.map(({id}) => find(allTmps, {id})))
    )
);

function getVariables(actions: Object): Array<string> {
  const code = getCodeFromActions(actions) || '';
  return [...new Set(code.match(/(@@|__)([a-zA-Z0-9_]*)(@@|__)/g))].map((v) =>
    v.replace(/(@@|__)/g, '')
  ); // ...new Set -> get array w/ distinct values
}

function listIntentsByTemplateId(state: Object, id: string): Array<string> {
  return get(state, PATHS.suggested, [])
    .filter(({macro}) => macro.toString() === id)
    .map(({intent}) => intent);
}

function getAssistanceType(state: Object, id: string): FullAssistanceType {
  const autoRepliedTemplates = get(state, PATHS.autoReply, []);
  const preparedTemplates = get(state, PATHS.prepared, []);
  const suggestedTemplates = get(state, PATHS.suggested, []).map(({macro}) =>
    macro.toString()
  );

  if (autoRepliedTemplates.includes(id)) {
    return 'autoReplied';
  } else if (preparedTemplates.includes(id)) {
    return 'prepared';
  } else if (suggestedTemplates.includes(id)) {
    return 'assisted';
  } else {
    return 'none';
  }
}

function getFilter(state: Object, id: string): ?Object {
  const filters = get(state, PATHS.filters, []).map((l) => ({
    ...l,
    id: l.id.toString(),
  }));
  return find(filters, {id});
}
