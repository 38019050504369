import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {UncontrolledTooltip as Ut} from 'reactstrap';
import {Paperclip} from 'react-feather';

import i18n from '../../../../i18n';
import {NO_ASSISTANCE_USAGES_FULL} from '../../../../lib/analytics';
import {USAGES} from './utils';
import Table from '../../../../components/table';

import useIntents from './useIntents';

export const IntentsTable = ({
  dateRange,
  macro,
}: {
  dateRange: {startDate: Date; endDate: Date};
  macro: string;
}) => {
  const {formatedIntentData, loading} = useIntents({dateRange, macro});

  return (
    <StyledTable>
      <Table
        isLoading={loading}
        data={formatedIntentData}
        columns={useMemo(
          () => [
            {
              Header: i18n.t('Category'),
              accessor: 'title',
            },
            ...NO_ASSISTANCE_USAGES_FULL.map((accessor) => ({
              accessor,
              Header: USAGES[accessor].title,
              Cell: ({cell: {value}}: {cell: {value?: string}}) =>
                value ? value : <span>0</span>,
            })),
            {
              Header: 'Total',
              accessor: 'total',
            },
            {
              Header: '',
              accessor: 'key',
              Cell: ({cell: {value}}: {cell: {value: string}}) => (
                <>
                  <Link
                    to={{
                      pathname: `/config/intents/${value}`,
                      search: window.location.search,
                    }}
                    target="_blank"
                    id={`redirect-${value}`}
                  >
                    <Paperclip color="grey" size={12} className="clic" />
                  </Link>
                  <Ut target={`redirect-${value}`}>
                    {i18n.t('Associate with this category')}
                  </Ut>
                </>
              ),
            },
          ],
          []
        )}
      />
    </StyledTable>
  );
};

export default IntentsTable;

const StyledTable = styled.div`
  th:first-child {
    width: 300px;
  }
  th:nth-child(6),
  td:nth-child(6) {
    font-weight: bold;
  }
  th:last-child,
  td:last-child {
    width: 10px;
    text-align: right;
  }
`;
