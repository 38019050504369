import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import styled from 'styled-components';

import i18n from '../../../../i18n';

const Dropdown = ({
  formatedMacroData,
  macro,
  setMacro,
}: {
  formatedMacroData: Array<{id: string; title: string}>;
  macro?: string;
  setMacro: Function;
}) => (
  <StyledDropdown>
    {macro ? (
      <DropdownToggle color="hubware">
        {formatedMacroData.find(({id}) => id === macro)?.title ?? macro}
      </DropdownToggle>
    ) : (
      <DropdownToggle caret color="hubware">
        {i18n.t('Categories by template')}
      </DropdownToggle>
    )}

    <DropdownMenu>
      <DropdownItem active={!macro} onClick={() => setMacro('')}>
        {i18n.t('By templates')}
      </DropdownItem>
      <DropdownItem divider />
      {formatedMacroData.map(({id, title}) => (
        <DropdownItem
          key={uuidv4()}
          active={macro === id}
          onClick={() => setMacro(id)}
        >
          {title || id}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </StyledDropdown>
);

export default Dropdown;

const StyledDropdown = styled(UncontrolledDropdown).attrs({
  className: 'mx-auto',
})`
  margin-bottom: 30px;

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: var(--hubware);
  }
`;
